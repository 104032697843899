<template>
  <div>
    <ClientHeader :client="client" />
    <v-row dense class="mb-3">
      <v-col cols="12" md="7" class="d-flex flex-column flex-md-row justify-space-around" style="gap: 10px">
        <v-text-field
          v-model="trackingNumber"
          type="number"
          outlined
          dense
          label="Tracking number"
        />
        <v-select
          dense
          outlined
          v-model="shipmentType"
          :items="shipmentTypes"
          :loading="shipmentTypesLoading"
          :disabled="shipmentTypesLoading"
          item-text="type"
          item-value="id"
          label="Shipment type"
        />
        <v-btn @click="createShipment" :loading="createShipmentLoading" :disabled="!shipmentType || !trackingNumber" color="primary">create</v-btn>
      </v-col>
    </v-row>
    <div v-if="!shipments && loading">
      <v-skeleton-loader type="text" />
    </div>
    <v-row dense v-else-if="shipments" v-for="(data, i) in shipments" :key="data.tracking_number">
      <v-col cols="12" md="7">
        <v-card class="pa-3" >
          <div class="d-flex justify-space-between px-4">
            <h3>Tracking number: {{ data.tracking_number}}</h3>
            <h3>Shipment type: {{data.shipment_type}}</h3>
          </div>
          <v-stepper vertical v-model="data.tracking" flat>
            <template v-for="(status,index) in data.tracking"  v-if="checkForUnwantedStatus(status)">
              <v-stepper-step
                :step="index"
                :complete="status.phase === 0 || status.phase === -1"
                :complete-icon="status.phase === 0 ? 'mdi-progress-clock' : 'mdi-check'"
              >
                <span
                  :class="status.phase === 0 ? 'font-weight-bold current' : null"
                  class="text-uppercase"
                  >{{ status.description }}</span
                >
                <small>{{ status.optionText }}</small>
              </v-stepper-step>
<!--              <v-stepper-content :step="status.step"></v-stepper-content>-->
            </template>
          </v-stepper>
          <v-card-actions class="d-flex justify-end">
<!--            <v-btn @click="deleteDialog = true" text color="red">delete</v-btn>-->
            <v-btn text @click="setShipmentToEdit(data, i)" color="primary">edit</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
<!--    <ConfirmCardDialog-->
<!--      :show.sync="deleteDialog"-->
<!--      confirm-btn-text="Accept"-->
<!--      cancel-btn-text="Cancel"-->
<!--      card-desc="Are you sure you want to delete this Shipment?"-->
<!--      card-title="Delete Shipment"-->
<!--      @confirmDialog=""-->
<!--    />-->
    <v-dialog
      v-model="editDialog"
      @click:outside="shipmentToEdit = {}"
      max-width="600px"
    >
      <v-card>
        <v-card-title>
          Edit shipment
        </v-card-title>
        <v-card-text class="text--primary text-body-1">
          <v-row dense align="center">
            <v-col cols="6">Tracking number</v-col>
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                hide-details
                v-model="shipmentToEdit.tracking_number"
              />
            </v-col>
          </v-row>
          <br>
          <v-row dense align="center">
            <v-col cols="6">Shipment type </v-col>
            <v-col cols="6">
              <v-select
                dense
                outlined
                hide-details
                v-model="shipmentToEdit.shipment_type"
                :items="shipmentTypes"
                item-text="type"
                :item-value="(item) => item"
                label="Shipment type"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn @click="shipmentToEdit = {}" text>cancel</v-btn>
          <v-btn
            :disabled="!shipmentToEdit.tracking_number ||
                        shipmentToEdit.tracking_number === shipments[editingShipmentIndex].tracking_number &&
                        shipmentToEdit.shipment_type.type === shipments[editingShipmentIndex].shipment_type"
            :loading="editLoading"
            @click="editShipment"
            text
          >
            save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ClientsService from "@/services/clients.service";
import ShipmentService from "../../services/shipment.service";
import ClientHeader from "@/components/Client/ClientHeader.vue";
import ClientService from "@/services/clients.service";
import ConfirmCardDialog from "../../components/ConfirmCardDialog.vue";

export default {
  name: "client-shipment",
  components: {ConfirmCardDialog, ClientHeader },
  data: () => ({
    shipments: null,
    clientId: null,
    client: null,
    loading: false,
    deleteDialog: false,
    editDialog: false,
    editLoading: false,
    shipmentToEdit: {},
    editingShipmentIndex: undefined,
    shipmentTypesLoading: false,
    trackingNumber: "",
    shipmentTypes: [],
    shipmentType: undefined,
    createShipmentLoading: false,
  }),
  methods: {
    checkForUnwantedStatus(e){
      if(e.statusCode === "CANCELLED" || e.statusCode === "UNKNOW"){
        if (e.phase > 0) return false;
      }
      return true;
    },
    getShipments() {
      this.loading = true;
      ClientsService.getShipments(this.clientId)
        .then((res) => {
          this.shipments = null;
          this.shipments = res;
          if (this.shipments)
            this.shipments.forEach(shipment => {
              const isUnknown = shipment.tracking[shipment.tracking.length - 1].phase === 0

              if (isUnknown)
                shipment.tracking.forEach((track, i) => {

                  if (i !== shipment.tracking.length - 1)
                    track.phase = null
                })
            })
        })
        .finally(() => (this.loading = false));
    },
    createShipment() {
      this.createShipmentLoading = true
      const payload = {
        shipment_type: this.shipmentType,
        tracking_number: this.trackingNumber
      }

      ShipmentService.createShipment(this.clientId, payload)
        .then(() => {
          this.trackingNumber = ""
          this.shipmentType = undefined
          this.getShipments()
        })
        .finally(() => this.createShipmentLoading = false)
    },
    getShipmentTypes() {
      this.shipmentTypesLoading = true;
      ShipmentService.getShipmentTypes()
        .then(res => this.shipmentTypes = res)
        .finally(() => this.shipmentTypesLoading = false)
    },
    getCurrentClient() {
      ClientService.getOneClient(this.clientId).then((res) => {
        this.client = res;
      });
    },
    editShipment() {
      const {tracking_number, shipment_type, shipment_id} = this.shipmentToEdit

      const payload = {
        tracking_number,
        shipment_type: shipment_type.id
      }
      this.editDialog = true
      ShipmentService.updateShipment(shipment_id, payload)
        .then(() => {
          this.shipmentToEdit = {}
          this.getShipments()
        })
        .finally(() => this.editDialog = false)
    },
    setShipmentToEdit(data, index) {
      this.shipmentToEdit = JSON.parse(JSON.stringify(data))
      this.editingShipmentIndex = index
    }
  },
  watch: {
    shipmentToEdit(val) {
      if (Object.keys(val).length !== 0) {
        const index = this.shipmentTypes.findIndex(type => type.type === val.shipment_type)


        val.shipment_type = this.shipmentTypes[index]

        this.editDialog = true
      } else {
        this.editDialog = false
      }
    }
  },
  created() {
    this.clientId = this.$route.params.clientId;
    this.getCurrentClient();
    this.getShipments();
    this.getShipmentTypes()
  },
};
</script>
