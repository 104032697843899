import $axios from '../plugins/axios'

class ShipmentService {
  getShipmentTypes() {
    return $axios.get('shipments/types').then(res => res.data)
  }

  createShipment(id, payload) {
    return $axios.post(`clients/${id}/shipments`, payload).then(res => res.data)
  }

  updateShipment(id, payload) {
    return $axios.patch(`/shipments/${id}`, payload).then(res => res.data)
  }
}

export default new ShipmentService()